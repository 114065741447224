<template lang="">
  <b-card>
    <b-row>
      <b-col cols="12">
        <b-col cols="8">
          <h3 class="font-weight-bolder">
            Filter
          </h3>
          <br>
          
          <form-v-select
            ref="region"
            v-model="region"
            dataurl="region"
            label="Region"
            placeholder="Semua Region"
            :item_text="'name'"
          />

          <b-form-group
            label-cols-md="4"
            label="Periode Type"
          >

            <b-form-select
              v-model="period_type"
              :options="[
                { value: 'annual', text: 'Annual' },
                { value: 'monthly', text: 'Monthly' },
                { value: 'weekly', text: 'Weekly' },
                { value: 'daily', text: 'Daily' },
              ]"
            />
          </b-form-group>

          <b-form-group
            v-if="period_type == 'annual'"
            label-cols-md="4"
            label="Periode"
          >
            <b-input-group>
              <cleave
                v-model="year"
                class="form-control"
                :options="{
                  numeral: true,
                  numeralThousandsGroupStyle: 'none',
                }"
                placeholder="Tahun"
              />
              <b-input-group-append is-text>
                Year
              </b-input-group-append>
            </b-input-group>
          </b-form-group>

          <b-form-group
            v-if="period_type == 'monthly'"
            label-cols-md="4"
            label="Periode"
          >
            <b-input-group>
              <b-form-select
                v-model="month"
                :options="[
                  { value: '1', text: 'January' },
                  { value: '2', text: 'Febuary' },
                  { value: '3', text: 'Maret' },
                  { value: '4', text: 'April' },
                  { value: '5', text: 'Mei' },
                  { value: '6', text: 'Juni' },
                  { value: '7', text: 'Juli' },
                  { value: '8', text: 'Agustus' },
                  { value: '9', text: 'September' },
                  { value: '10', text: 'Oktober' },
                  { value: '11', text: 'November' },
                  { value: '12', text: 'Desember' },
                ]"
              />
              <b-input-group-append is-text>
                Month
              </b-input-group-append>
              <cleave
                v-show="period_type == 'monthly'"
                v-model="year"
                class="form-control"
                :options="{
                  numeral: true,
                  numeralThousandsGroupStyle: 'none',
                }"
                placeholder="Tahun"
              />
              <b-input-group-append is-text>
                Year
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
          <b-form-group
            v-show="period_type == 'weekly' || period_type == 'daily'"
            label-cols-md="4"
            label="Periode"
          >
            <b-input-group>
              <flat-pickr
                v-model="date_start"
                class="form-control"
                placeholder="Tanggal Awal"
              />
              <b-input-group-append is-text>
                To
              </b-input-group-append>
              <flat-pickr
                v-model="date_end"
                class="form-control"
                placeholder="Tanggal Akhir"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col cols="8">
          <b-form-group
            label-cols-md="4"
            label=""
          >
            <b-button
              class="mr-1"
              size="sm"
              variant="primary"
              type="button"
              @click="show"
            >Show</b-button>
            <b-button
              class="mr-1"
              size="sm"
              variant="outline-secondary"
              type="button"
              @click="reset"
            >Reset</b-button>
          </b-form-group>
        </b-col>
      </b-col>
      <b-col cols="12">
        <hr>
      </b-col>
      <b-col cols="6">
        <h3 class="font-weight-bolder">
          Total Kendaraan Baru
        </h3>
        <br>
        
        <vue-apex-charts 
          ref="realtimeChartVehicle"
          type="bar" 
          height="250" 
          :options="chartOptionsComputedLine" 
          :series="vehicleSeries"
        />
      </b-col>
      <b-col cols="5">
        <h3 class="font-weight-bolder">
          Model Kendaraan
        </h3>
        <br>
        <vue-apex-charts 
          ref="realtimeChartBBM" 
          type="pie" 
          height="350" 
          :options="chartOptionsComputed" 
          :series="typeSeries"
        />
      </b-col>
      <b-col cols="12">
        <hr>
      </b-col>
      <b-col
        cols="4"
        style="border-style: groove;"
      >
        <div class="truncate">
          <h3 class="font-weight-bolder">
            Total Dalam Perawatan
          </h3>
        </div>
        <b-row>
          <b-col cols="6">
            <div class="card bg-light-primary rounded">
              <div
                class="card-header"
                style="align-self: center; text-align: center;"
              >
                <div>
                  <h2 class="mb-0">
                    <b>{{model.maintenance?model.maintenance.ongoing:0}}</b>
                  </h2>
                  <br>
                  <p class="card-text">
                    Baru
                  </p>
                </div>
              </div>
            </div>
          </b-col>
          <b-col cols="6">
            <div class="card bg-light-primary rounded">
              <div
                class="card-header"
                style="align-self: center; text-align: center;"
              >
                <div>
                  <h2 class="mb-0">
                    <b>{{model.maintenance?model.maintenance.overdue:0}}</b>
                  </h2>
                  <p class="card-text">
                    Lewat Tanggal
                  </p>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-col>
      <b-col
        cols="4"
        style="border-style: groove;"
      >
        <div class="truncate">
          <h3 class="font-weight-bolder">
            Total Issue
          </h3>
        </div>
        <b-row>
          <b-col cols="6">
            <div class="card bg-light-primary rounded">
              <div
                class="card-header"
                style="align-self: center; text-align: center;"
              >
                <div>
                  <h2 class="mb-0">
                    <b>{{model.issue?model.issue.ongoing:0}}</b>
                  </h2>
                  <br>
                  <p class="card-text">
                    Baru
                  </p>
                </div>
              </div>
            </div>
          </b-col>
          <b-col cols="6">
            <div class="card bg-light-primary rounded">
              <div
                class="card-header"
                style="align-self: center; text-align: center;"
              >
                <div>
                  <h2 class="mb-0">
                    <b>{{model.issue?model.issue.overdue:0}}</b>
                  </h2>
                  <p class="card-text">
                    Lewat Tanggal
                  </p>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-col>
      <b-col
        cols="4"
        style="border-style: groove;"
      >
        <div class="truncate">
          <h3 class="font-weight-bolder">
            Status Kendaraan
          </h3>
        </div>
        <b-row>
          <b-col cols="6">
            <div class="card bg-light-primary rounded">
              <div
                class="card-header"
                style="align-self: center; text-align: center;"
              >
                <div>
                  <h2 class="mb-0">
                    <b>{{model.status?model.status.active:0}}</b>
                  </h2>
                  <br>
                  <p class="card-text">
                    Aktif
                  </p>
                </div>
              </div>
            </div>
          </b-col>
          <b-col cols="6">
            <div class="card bg-light-primary rounded">
              <div
                class="card-header"
                style="align-self: center; text-align: center;"
              >
                <div>
                  <h2 class="mb-0">
                    <b>{{model.status?model.status.non_active:0}}</b>
                  </h2>
                  <br>
                  <p class="card-text">
                    Tidak Aktif
                  </p>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="12">
        <hr>
      </b-col>
      <b-col cols="12">
        <h3 class="font-weight-bolder">
          Daftar Penggunaan Kendaraan Baru
        </h3>
        <br>
        
        <b-overlay
          :show="loading"
          variant="light"
          :opacity="0.5"
          rounded="sm"
        >

          <basetable
            ref="basetable"
            :dataurl="''"
            :fields="fieldsTable"
            :filter="filter"
            :is-filter="false"
            :is-view="false"
            :is-edit="false"
            :is-delete="false"
            :is-add="false"
            :is-pagination="false"
          >

            <template #cell(count)="data">
              <span>{{ data.item.count|numFormat }}</span>
            </template>
            <template #cell(total_km)="data">
              <span>{{ data.item.total_km|numFormat }}</span>
            </template>
          </basetable>
        </b-overlay>
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
import { BCard,BButton,BFormGroup,BFormSelect,BRow,BCol,BOverlay,BInputGroup, BInputGroupAppend } from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { pieChartOptions } from '../../vehicle/chartOptions'
import { lineChartOptions } from '../../vehicle/chartOptions'
import BaseInfo from '@/views/base/BaseInfo.vue'
import FormVSelect from '@/views/base/form/FormVSelect.vue'
import flatPickr from 'vue-flatpickr-component'
import Cleave from 'vue-cleave-component'
import moment from 'moment'
import basetable from '@/views/base/BaseTable.vue'

export default {
  components: {
    BOverlay,
    BCard,
    BButton,
    BFormGroup,
    VueApexCharts,
    BFormSelect,
    BRow,
    BCol,
    BaseInfo,
    FormVSelect,
    flatPickr,
    BInputGroup,
    BInputGroupAppend,
    Cleave,
    basetable
  },
  data() {
    return {
      dataurl:"/dashboard/vehicle",
      loading:false,
      fieldsTable:[
        {key:'branch_name', label:'Regional'},
        {key:'model', label:'Jenis Kendaraan'},
        {key:'count', label:'Jumlah'},
        {key:'total_km', label:'KM Total'},
      ],
      vehicleSeries:[],
      typeSeries:[20,45, 30],
      item:[],
      region:null,
      period_type:'annual',
      date_end:moment().format('YYYY-MM-DD'),
      date_start:moment().format('YYYY-MM-DD'),
      month:'1',
      year:moment().format("YYYY"),
      filter:[],
      model:[]
    }
  },
  computed: {
    chartOptionsComputed() {
      const options = JSON.parse(JSON.stringify(pieChartOptions))
      return options
    },
    chartOptionsComputedLine() {
      const options = JSON.parse(JSON.stringify(lineChartOptions))
      return options
    },
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    show(){
      this.filter['filter[branch_id]'] = this.region?this.region.id:null

      this.fetchData()
    },
    reset(){
      this.filter = []
      this.region = null
      this.year = moment().format("YYYY")
      this.month = 1
      this.date_start = moment().format('YYYY-MM-DD')
      this.date_end = moment().format('YYYY-MM-DD')
      this.period_type = 'annual'
      this.fetchData()
    },
    fetchData(){
      this.filter['periode_type'] = this.period_type
      var start, end
      if(this.period_type == 'annual'){
        start = moment(this.year+"-01-01").clone().startOf('month').format('YYYY-MM-DD')
        end = moment(this.year+"-12-01").clone().endOf('month').format('YYYY-MM-DD')
        
      }else if(this.period_type == 'monthly'){
        start = moment(this.year+"-"+this.month+"-01").clone().startOf('month').format('YYYY-MM-DD')
        end = moment(this.year+"-"+this.month+"-01").clone().endOf('month').format('YYYY-MM-DD')
      }else if(this.period_type == 'weekly'){
        var diff = moment.duration(moment(this.date_end).diff(moment(this.date_start))).asDays()
        if(diff > 7)
          return this.$bvToast.toast('Range Weekly max 7 days', {
            title: 'Periode Error',
            solid: true,
            variant:'danger'
          })
        else{
          start = this.date_start
          end = this.date_end
        }
      }else{
        var diff = moment.duration(moment(this.date_end).diff(moment(this.date_start))).asDays()
        if(diff > 30)
          return this.$bvToast.toast('Range Weekly max 30 days', {
            title: 'Periode Error',
            solid: true,
            variant:'danger'
          })
        else{
          start = this.date_start
          end = this.date_end
        }
      }

      if(start == null || start == "" || end == null || end == ""){
        return this.$bvToast.toast('Range Periode tidak di ketahui', {
            title: 'Periode Error',
            solid: true,
            variant:'danger'
          })
      }

      this.filter['between[vehicles.created_at]'] = start+","+end

      var params ={...this.filter}
      this.$http.get(this.dataurl, {params:params}).then(res => {
        this.model = res.data
        this.$refs.basetable.setDataTable(res.data.table)
        this.setDataType(res.data.type)
        this.setDataVehicle(res.data.new)
      })
    },
    setDataVehicle(result){
      let label = []
      let data = []
      this.vehicleSeries = []
      for(let i=0; i<result.length; i++){
        if(this.period_type == 'annual'){
          label.push(result[i].key)
        }
        data.push(parseFloat(result[i].amount))
      }
      this.vehicleSeries = [{data:data}]
      this.$refs.realtimeChartVehicle.updateOptions(
        { 
          xaxis: {
            type:'string',
            categories: label
          }
        })
    },
    setDataType(result){
      let label = []
      this.typeSeries = []
      for(let i=0; i<result.length; i++){
        label.push(result[i].model)
        this.typeSeries.push(parseFloat(result[i].count))
      }
      this.$refs.realtimeChartBBM.updateOptions({ labels: label })
    },
  },
  
}
</script>
<style lang="scss">
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>